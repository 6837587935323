import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import { motion } from "framer-motion"

import Logo from '../../svg/logo'
import { cn } from '@/utils/cn'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '../ui/breadcrumb'
import { getLabelByHref } from '@/utils/label'
import { useDevtools } from '@/hooks/useDevtool'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

export default function Header({ title, className }: { title?: string, className?: string }) {
  const router = useRouter()
  const devtools = useDevtools()
  const path = router.asPath
  const label = getLabelByHref(devtools, path)
  const isHomePage = router.pathname === '/'
  const t = useTranslations('Layout.Header')
  const labelT = useTranslations('Layout.Labels')

  const handleLanguageChange = (value: string) => {
    router.push(router.pathname, router.asPath, { locale: value })
  }

  return (
    <motion.header 
      className={cn('font-serif flex justify-between items-center header py-3 sticky top-0 z-50 w-full backdrop-blur transition-all duration-300', className)}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <motion.div 
          className="flex items-center"
          whileHover={{ scale: 1.03 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <Link href="/" prefetch={false} className="flex items-center gap-2">
            <Logo className="text-orange-500 cursor-pointer w-8 h-8" />
            <span className="text-2xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-orange-700">
              {title ?? t('title')}
            </span>
          </Link>
        </motion.div>

        <div className="flex items-center gap-5">
          {!isHomePage && (
            <Breadcrumb className="hidden md:flex">
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink href="/">{t('home')}</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                {label && (
                  <>
                    <BreadcrumbItem>
                      <BreadcrumbLink href={`/for-${label}`}>
                        {labelT(label)}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                  </>
                )}
                <BreadcrumbItem>
                  <BreadcrumbLink href={path}>{title}</BreadcrumbLink>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          )}
          
          <a 
            href="https://xunlianying.feishu.cn/share/base/form/shrcnJvE9nNqrFsl32PT01mvooh" 
            className="no-underline text-sm font-medium text-gray-600 hover:text-orange-500 transition-colors" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {t('feedback')}
          </a>
          
          <Select
            value={router.locale || router.defaultLocale}
            onValueChange={handleLanguageChange}
          >
            <SelectTrigger className="w-[100px] border-orange-200 focus:ring-orange-200">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en">English</SelectItem>
              <SelectItem value="zh">中文</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </motion.header>
  )
}
